
.piece {
    /* font-family: serif, sans-serif, fixed !important;
    text-align: center;
    cursor: pointer;
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    padding: auto;
    height: 12vw; */

    /* cursor: pointer;
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: absolute;
    height: 12vw;
    width: 12vw;
    bottom: -0.3vw;
    left: -0.1vw; */

    cursor: pointer;
    background-size: 60%;
    background-repeat: no-repeat;
    /* background-position: 50% 50%; */
    position: absolute;
    height: 100%;
    width: 100%;
    top: 10%;
    left: 20%;
}
