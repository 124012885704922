
/*
====================================================================
Global
====================================================================
*/
* {
    font-family: consolas, 'Courier New', Courier New, Courier, Monospace, arial;
    font-size:1.004em;
}

*:not(#copyUrl, .copytext, INPUT) {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

input, input:before, input:after, .copytext {
    -webkit-user-select: initial;
    -khtml-user-select: initial;
    -moz-user-select: initial;
    -ms-user-select: initial;
    user-select: initial;
   } 

/*
====================================================================
Logo
====================================================================
*/
.logo {
    display:inline-flex;
}

.logo A {
    vertical-align: middle;
    padding-top: 0.85em;
    overflow-wrap: anywhere;
}


   
/*
====================================================================
Forms
====================================================================
*/
form {
    /* width:20em; */
}



form label {
    display:block;
}

form label input {
    display:inline-block;
    right:0;
}

INPUT[type=radio] {
    margin: 1em;
}

input { -webkit-user-select:text;}


/* 
====================
    Layout
=====================
*/
.container {
    flex-direction: column;
}

.inline > * {
    display: inline-flex;
}

#root {
    min-height: 50em;
}

#root > * {
    min-height: 50em;
}

.right {
    text-align: right;
}

.game {
    margin: 0;
    padding: 0;
}

.spinner {
    position: absolute;
    top: 40%;
    left: 40%;
    width: 4em;
    height: 4em;
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    z-index: 10000;
    animation: spin 1s linear infinite;
    /* border-radius: 1em; */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.sidebar {
    padding: 1em;
    overflow: auto;
}
.sidebar::-webkit-scrollbar {
    width: 10em; /* Set the width of the scrollbar */
}

.banner {
    height: 6em;
    text-align: center;
    padding: 2em;

}


.home {
    width: 60%;
    margin:auto;
}

.home h1 {

}

.home {
    text-align:center;
}

.home section {
    /*width: 10em;*/
    height: 10em;
    vertical-align: central;
    border:solid 1px silver;
    padding: 1em;
    text-align: center;
    margin-top: 1em;
}





/*
====================================================================
Connection
====================================================================
*/

span.connection:after {
    content: "\25CF";
    margin-left: 0.2em;
}


span.connection {
    font-family: Wingdings;
    color: red;
    font-size: 1.4em;
}

span.connection.down {
    color: red;
}

span.connection.online {
    color: green;
}

span.connection.away {
    color: gold;
}

span.connection.unknown {
    color: #999;
}

span.connection.network {
    color: red;
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
      opacity: 0;
    }
  }




/*
====================================================================
PIECE
====================================================================
*/
.piece {
    /* height: 10vw; */
}


/*.gamemenu INPUT[type=radio] {
    margin: 1em;
}*/

/*
====================================================================
ADD PIECE
====================================================================
*/

.addPiece {
    position: absolute;
    background-color: rgba(200, 200, 200, 0.8);
    width: 80%;
    height: 80%;
    top: 10%;
    left: 10%;
    border: solid white 2px;
    padding: 5%;
    z-index: 1000;
}

.addPiece LI {
    position: relative;
    list-style: none;
    width: 20%;
    height: 20%;
    background-size: 65% auto;
    /* background-size: 100% 100%; */
    /* background-size: contain; */
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin:auto;
}

.addPiece LI .piece {
    height: 5em;
    width: 5em;
}


.icon {
    min-width: 2em;
    min-height: 2em;
    padding-top: 0.3em;
    
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    
}

/*
====================================================================
MOVES
====================================================================
*/
ol.moves {
    font-weight: 700;
}
ol.moves .move {
    padding: 0.2em 0.3em;
}
.moves span {
    margin-right: 1em;
}


/* .playerbar > div:nth-child(2) {
    flex-
} */

.vertical {
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-direction: column;
}

.vertical > * {
    /* flex-direction: row; */
    flex: 1;
    display: flex;
}

.share {
    width: 2.5em;
    
}

.shared-opened {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.code {
    font-family:monospace;
    width: 75%;
    user-select: all;
    overflow-wrap: anywhere;
    font-size: 0.8em;
}

.unshared {
    background-color: #666;
    color: #ccc;
}

.option {
    margin-bottom: 1em;
    align-items: center;
}

.shared {
    /* background-color: #ccc; */
    /* color:#666; */
    /* background-color: rgba(50,200,50,0.7); */
    background-color: dodgerblue;
}

.option BUTTON {
    width: 2.5em;
    height: 2.5em;
    margin-right: 1em;
}

.flag {
    /* background-color: silver; */
    /* height: 100%; */
    mask-size: contain;
    -webkit-mask-size: contain;
    padding: 0.2em;
    width: 2.5em;

    

}



/* ========================= */
.flag img {
    /* height: 1em; */
    /* width: 1em; */
    width:100%;
    /* filter: url(#flagFilter); */
    border:1px #333 solid;
}


/*
    ====================================================================
    Host
    ====================================================================
*/
.host H3 {
    /* font-size: 4vw; */
    overflow-wrap: anywhere;
}
.host .flag {
    /* align-items: center; */
    margin: auto;
}

/*
    ====================================================================
    #cookies
    ====================================================================
*/
#cookies {
    /* display: none; */
    position: absolute;
    top: 5em;
    right: 3em;
    width: 20em;
    height: 30em;
    background-color: #ffd700;
    color: black;
    font-weight: 400;
    z-index: 100000;
    border-radius: 1em;
    text-align: center;
    padding: 1em;
    line-height: 1.8em;
}
#cookies A {
    padding: 5px;
}

.pos {
    font-weight: 800;
    background-color: white;
    color: black;
    border-radius: 0.5em;
    text-decoration: none;
}
.neg {
    font-weight: 800;
    color: cornflowerblue;
}


/*
    ====================================================================
    Other bits, are any used?
    ====================================================================
*/
UL {
    list-style-type: none;
    padding:0;
}



.vote {
    width: 100%;

}

/* .vote > * {
    display:inline-flex;
    vertical-align: middle;
} */

.vote .piece {
    width: 1em;
}

.gamemenu { 
    text-align: center  
}

.gamemenu LI {
    list-style: none;
}




.paging {
    display:flex;
}

.paging li {
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin: auto;
    width: 20%;
    -ms-flex-pack: center;
    justify-content: center;
    text-decoration: none;
    font-weight: 800;
    font-size: 2em;
    padding: 0.6em;
}

.paging .pageable {
    background-color:lightblue;
}

.paging SPAN {
    margin-top: 0.4em;
    text-align: center;
}


/*
    ====================================================================
    Footer
    ====================================================================
*/
.footer {
    margin-top: 5em;
    text-align: center;
    font-size: 0.8em;
    height: 10em;
}

.footer > * {
    vertical-align: bottom;
}

button + h5 {
    display: inline;
    margin: 0.3em 1em;
    font-size: 0.7em;
}

/* ===================== tooltips ==================*/
/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  }
  
  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
  
    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
  
    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  /* Tooltip arrow */
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }


/* ===================== dialogs ================== */
.MuiDialog-root form {
    padding: 2em 1em;
}

/* ===================== ADVERT =================== */
.advert
{
    height: 3em;
    background-color: #aaa;
    border: solid 0.3em #666;
    text-align: center;
    font-family: sans-serif;
    color: #ccc;
    /* padding-top: 2em; */
    padding-top: 0.5em;
    width:100%;
}
