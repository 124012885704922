
/*
    ====================================================================
    THEMES
    ====================================================================
*/
.theme.light {
    background-color: #fafafa;
    color: #333;
}

.theme.light A {
    color: #333;
    text-decoration: none;
}

.theme.light A:hover {
    color: #555;
    text-decoration: none;
}

.theme.light .settings li {
    background-color: #666;
    color: #eee;
}

.theme.light .navbar LI .active a {
    background-color: aliceblue;
}

.theme.light .navbar LI .active a {
    background-color: aliceBlue;
}

.theme.dark {
    background-color: #666;
    color: #eee;
}

.theme.dark A {
    color: deepskyblue;
    text-decoration: none;
}

.theme.dark A:hover {
    color: cornflowerblue;
    text-decoration: none;
}

.theme.dark .navbar {
    background-color: #666;
    background-image: none !important;
}

.theme.dark .navbar LI a, .theme.dark .navbar-brand {
    color: #eee;
}

.theme.dark .settings li {
    background-color: #eee;
    color: #666;
}

.theme.dark input, .theme.dark option, .theme.dark result {
    color: #666;
}

.theme select {
    color: #666;
}

.theme.dark .paging LI {
    color: #666;
}


.theme.dark .history A {
    color: #eee;
}

.theme.dark .history .winner A {
    color: yellowgreen;
}

.theme.light .history A {
    color: #666;
}