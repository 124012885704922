/*
    ====================================================================
    History
    ====================================================================
*/
.history {
    width: 100%;
    padding:0;
}

.history UL {
    width: 100%;
    padding: 0;
}

.history li {
    /* padding: 1em; */
    list-style-type: none;
    background-color: #eee;
    margin-top: 1em;
    cursor: pointer;
    min-height: 4em;
    text-align: center;
    margin-left: 1em;
    margin-right: 1em;
}



.history li a {
    list-style-type: none;
    /* background-color: #eee; */
    /* margin-top: 1em; */
    cursor: pointer;
    /* padding: 1em; */
    width:100%;
}

.history li span {
    display:block;
}

.history li.turn, .history li.noturn {

}

.history li.turn {
    background-color: yellowgreen;
}

.history li.noturn {
    background-color: indianred;
}

.history LI.setup {
    background: linear-gradient( to bottom right, #eef 0%, #ddf 100%);
    color: black;
}

.dark .history li.abandoned {
    background-color: #333;
}

.light .history li.abandoned {
    background-color: #DDD;
}