
/*
    ====================================================================
    BATTLEBOX
    ====================================================================
*/
.result {
    font-weight: 700;
    /* font-size: 1.4em; */
    min-height: 2em;
    padding: 0.3em;
    /*width: 100%;*/
    display: flex;
    text-align: center;
}


.result.winner {
    background-color: palegoldenrod;
    /*border: solid 2px green;*/
    /* background: -webkit-gradient(linear, left top, left bottom, from(red), to(transparent)), -webkit-gradient(linear, right bottom, left top, from(lime), to(transparent)), -webkit-gradient(linear, left bottom, right top, from(blue), to(transparent)); */
    color: white;
}

.result.winner TD {
    background-color: palegoldenrod;
    /*border: solid 2px green;*/
    /* background: -webkit-gradient(linear, left top, left bottom, from(red), to(transparent)), -webkit-gradient(linear, right bottom, left top, from(lime), to(transparent)), -webkit-gradient(linear, left bottom, right top, from(blue), to(transparent)); */
    color: white;
}

.result.loser {
    /*border: solid 2px red;*/
    background-color:darkslategrey;
    color:#666;
}

.result.draw {
    background-color: #aaa;
    color: #666;
}

.result.win {
    /*background-color: white;
color: black;*/
    /*border: solid 3px gold;*/
    background-color: palegoldenrod;
    color: black;        
}

.result.draw {

}

.result.setup {
    background-color: lightsalmon;
}

.result.inprogress {
    display:none;
}

.result.setup .glyphicon {
    margin-left: 1em;
    font-size: 1.5em;
}

.result.inprogress {
    display:none;
}

.result.draw.alt {
    background-color: black;
    color: white;
}


.result > * {
    margin:auto;
}

    