/* a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
} */

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
/* 
.navbar {
    margin-bottom: 0em;
}

.navbar BUTTON {
    padding: 0;
} */

a:not([href]) {
    cursor: pointer;
}

a:not([href]:hover) {
    cursor: pointer;
}


.container {
    display: flex;
}

.row .container
{
    /* margin:auto; */
    padding:0;
}

.navbar {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    width:100%;
    padding: 0;
}

.navbar > * {
    /* width: 50%; */
}

.navbar > * > * {
    align-items: center;
}

.navbar svg {
    vertical-align: baseline;
    margin: 0.4em;
}

li.nav-item {
    /* display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center; */
    margin: 0.2em 1em;
}


.navbar a {
    /* padding: 0.2em 1em;
    text-decoration: none; */
}

.navbar-nav {
    /* margin-top: 1em; */
    flex-direction: row;
    color:white;
}

.theme.dark .navbar-nav a {
    color:white;
}

.theme.light .navbar-nav a {
    color:#666;
}


.theme.dark .navbar a {
    color:white;
}

.theme.light .navbar a {
    color:#666;
}

button.navbar-toggler:focus {
    border: solid rgba(255, 50, 50, 0.8) 1px;
}

button.navbar-toggler {
    /* background-color: rgba(220, 220, 220, 0.6)
    ; */
}



