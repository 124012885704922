
/*
    ====================================================================
    BATTLEBOX
    ====================================================================
*/
.battlebox {
    font-weight: 700;
    min-height: 3em;
    display: flex;
    justify-content: flex-start;
}


.battlebox .copy {
    background-color: #888;
    padding: 0.2em;
    color: #eee;
    border: solid 1px;
}


.playerbar.opponent H3 {
    /* text-align: left;
    justify-content: flex-start;
    text-align: left; */
    text-align: right;
}

.playerbar.opponent .player {
    /* text-align: left;
    justify-content: flex-start;
    text-align: left; */
    flex-direction: row-reverse;
}


.playerbar.principal H3 {
    /* text-align: right;
    justify-content: flex-end;
    text-align: right; */
}


.playerbar.principal * {
    /* align-items: right; */
    place-content: flex-end;
}


.battlebox .playerbar {
    /*display: inline-flex;*/
    /*flex: 1;
    display: flex;
    justify-content: flex-end;*/
    /* display: flex; */
    flex-flow: row nowrap;
    height: 100%;
    padding: 0.5em;
    flex:1;
    /* flex-direction: column; */
    justify-content: space-between
}

.battlebox .playerbar > * {
    /* width: 49%;
    height: 49%; */
    /* flex: 1; */
    /* flex-direction: row; */
    flex-basis: content;
    display:block;
}

.battlebox .playerbar > * > * {
    /* display:flex;
    flex-direction: row-reverse; */
    /* font-size:0.8em; */
    margin: 0.2em;
    /* align-items: center; */
}


.battlebox .playerbar.right .player {
    /* display:flex;
    flex-direction: row-reverse; */
    /* font-size:0.8em; */
    direction: rtl;
}

.battlebox .playerbar.right > * > * {
    /* display:flex;
    flex-direction: row-reverse; */
    /* font-size:0.8em; */
    /* direction: rtl; */
    align-items: flex-end;
}

.battlebox .playerbar > * > * button {
    /* margin-bottom: 0.5em;; */
}

.playerbar .player {
    justify-content: space-between;
}



.battlebox .playerbar .subheading
{
    margin-top: 0.8em;
    font-size:0.7em;
}

.battlebox .playerbar ul.stats {
    /* font-size: 0.8em; */
    margin-bottom: 0em;
    display:flex;
    /* flex-direction: row-reverse; */
    font-weight: 900;
    padding:0;
}

.battlebox .playerbar ul.stats li {
    margin: 0.5em 0.4em;
}

.captured {
    display: flex;
    flex-direction: row-reverse;
    position: relative;
}


.playerbar h3 {
    /*margin-left: 0.4em;*/
    margin:0;
    font-size:1.3em;
}

.playerbar A {
    overflow-wrap: anywhere;
}

.playerbar.white H3, .playerbar.white H3 A {
    color: white;
    text-shadow: silver 1px 1px;
}

.playerbar.black H3, .playerbar.black H3 A {
    color: black;
    text-shadow: #666 1px 1px;
}

.playerbar.blue H3, .playerbar.blue H3 A {
    color: cornflowerblue;
    text-shadow: navy 1px 1px;
}

.playerbar.red H3, .playerbar.red H3 A {
    color: crimson;
    text-shadow: #666 1px 1px;
}

.playerbar.red.noturn h3 {
    color: #d99;
    text-shadow: crimson 2px 2px;
}

.opponent .playerbar .captured {
    text-align: right;
    justify-content: flex-end;
    text-align: right;
}

.principal .playerbar .captured {
    text-align: left;
    justify-content: flex-start;
    text-align: left;
}


.playerbar .captured {
    /*margin-left: 0.2em;*/
    /* height: 1.6em; */
    width:100%;
    
}

.playerbar .captured .piece {
    display: inline-block;
    position: relative;
    /* font-size: 0.6em; */
    left:0;
    bottom:0;
    line-height: 2em;
    height: 2em;
    width: 2em;
}

.playerbar.winner {
    border: solid 3px gold;
    /* background-color: tan; */
    background: -webkit-gradient(linear, left top, left bottom, from(red), to(transparent)), -webkit-gradient(linear, right bottom, left top, from(lime), to(transparent)), -webkit-gradient(linear, left bottom, right top, from(blue), to(transparent));
    /*color: white;*/
}

.playerbar.loser {
    background-color: #666;
}

/*.battlebox.principal .captured {
    text-align:right;
    padding-right: 0.5em;
}*/

.battlebox.noop {
    background-color: #77a;
}

.battlebox.join {
    background-color: #77a;
}

.playerbar.turn {
    /*background-color: #7a7;*/
    background-color: yellowgreen;
}

.playerbar.noturn {
    /*background-color: #a77;*/
    background-color: indianred;
}

.playerbar.voted {
    /*background-color: #a77;*/
    background-image: linear-gradient(yellowgreen, cornflowerblue);
}

.battlebox.mymove {
    border:solid 1px red;
}