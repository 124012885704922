
/*
    ====================================================================
    STATUS
    ====================================================================
*/

.status {
    width: 100%;
    background-color: rgba(200,200,200,0.4);
    padding-left: 0.5em;
    display: flex;
    min-height: 10em;
    padding-top: 1em;
}

.status > li {
    /* min-width: 10em; */
    /* margin: auto; */
    /*display: inline-flex;*/
    flex: 1;
    flex-direction: column;
    vertical-align: baseline;
    text-align: center;
}

.status > li > * {
    flex-direction: column;
    flex: 1;
}

.status H4 {
    font-size: 1.2em;
    font-weight: 700;
    color:rgba(60, 60, 60, 0.7);
    /* text-align: center; */
    /* width:100%; */
    
}

.status .move .piece, .status .vote .piece {
    font-size: 0.8em;
}

.move {
    width: 100%;
    position: relative;
}

.move > *, .vote > * {
    display:inline-flex;
    vertical-align: middle;
}

.move .piece, .vote .piece {
    /* width: 1em; */
    position:relative;
    bottom:0;
    left:0;
    font-size:1em;
    line-height: 2em;
    height: 2em;
    width: 2em;
}
