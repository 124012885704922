

/*
    ====================================================================
    SETTINGS
    ====================================================================
*/

.settings {
    width: 100%;
    padding: 1em;
    /*background-color: white;*/
}

.settings UL  {
    /*display:inline;*/
    width: 100%;
}

.settings LI {
    display: inline-flex;
    border: solid 2px silver;
    padding: 1em;
    margin-right: 1em;
    cursor:pointer;
}

.settings li.selected {
    border: solid 2px cyan;
    cursor:not-allowed;
}
