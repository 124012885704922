
/*
    ====================================================================
    Profile
    ====================================================================
*/

.profile b {
    /* vertical-align: top; */
    /* padding-right: 1em; */
    padding-top: 0.2em;
    /* font-size: 4vw; */
    overflow-wrap: anywhere;
}

.container .profile {
    /* padding: 0.2em 0.1em;
    display: inline-flex; */
    margin-left: auto;
}
.container .profile > * {
    /* display: inline; */
    display: inline-flex;
    /* margin-left: auto; */
    vertical-align: middle;
    /* align-items: flex-end;
}
.container .profile b {
    vertical-align: middle;
    /* padding-top: 0.7em; */

}
.right {
    margin-left: auto;
}
