/*
    ====================================================================
    Arena
    ====================================================================
*/

.arena {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
}

.arena.one {
    grid-column: 1 / 3;
    grid-row: 1;
}
.arena.two {
    grid-column: 2 / 4;
    grid-row: 1 / 3;
}
.arena.three {
    grid-column: 1;
    grid-row: 2 / 5;
}
.arena.four {
    grid-column: 3;
    grid-row: 3;
}
.arena.five {
    grid-column: 2;
    grid-row: 4;
}
.arena.six {
    grid-column: 3;
    grid-row: 4;
}

.arena .card {
    /* width: 20%;
    height: 20%; */
    min-height: 10em;
}

.arena .board {
    /* width:  */
}

.arena .piece {
    height: 3.5vw;
    width: 3.5vw;
    bottom: -0.4vw;
    left: 0.1vw;
}


.arena .board TD {
    /* width:  */
    width: 3em;
    height: 3em;
}