/*
    ====================================================================
    Layout
    ====================================================================
*/
.board {
    background-color: #eee;
    border: 1px solid silver;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    max-width: 60em;
}

.board .tile {
    border: 0.1em dotted #666;
    position: relative;
    /* content: "";
    color: #999;
    font-weight: 500; */
}

.board td:before {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.board .tile span {
    position: absolute;
    bottom: -0.2em;
    left: 0.1em;
    font-size: 0.6em;
    color: #999;
    font-weight: 500;
}

.board.expanded .tile {
    height: 8em;
}

.board.expanded .tile .piece {
    font-size:5em;
}

.board.expanded .tile span {
    font-size:1.5em;
}

/*
    ====================================================================
    Colour Settings
    ====================================================================
*/
.board.grey .tile.nor {
    background-color: #eee;
}
.board.grey .tile.alt {
    background-color: #999;
}

.board.classic .tile.nor {
    background-color: #fafafa;
}
.board.classic .tile.alt {
    background-color: lightblue;
}

.board .tile.null {
    background-color:black;
    border:none;
}

.board .tile.null > * {
    display:none;
}

.board .tile.invalid {
    border-color: rgb(255, 63, 102);
}

/*
    ====================================================================
    Move selections
    ====================================================================
*/

.board .tile.nor.selected, .board .tile.alt.selected {
    /* border-color: #ffa; */
    background-color: rgba(255,140,0,0.8);
    /* background-color: darkorange; */
}

.board .tile.available {
    /* border-color:#8ae; */
    cursor:pointer;
    background-color: rgba(88,170,238,0.4);
}

.board .tile.nor.available, .board .tile.alt.available {
    /* border-color: darkorange; */
    /* border-width: 0.3em; */
    background-color: rgba(255,140,0,0.4);
    /* border-style: solid; */
}

.board td.available SPAN {
    bottom: -0.15em;
}

/*
    ====================================================================
    Vote selections
    ====================================================================
*/

/* ==== voting === */
.board .tile.nor.voting, .board .tile.alt.voting {
    background-color: rgba(6, 138, 247, 0.8);
    /* border-color: rgba(6, 138, 247, 0.8); */
    /* border-style: solid; */
}

.board .tile.nor.candidate, .board .tile.alt.candidate {
    background-color: rgba(6, 138, 247, 0.6);
    /* border-color: rgba(6, 138, 247, 0.8); */
    /* border-style: solid; */
    cursor:pointer;
    /* transition: border-style 1s ease; */
}




/* ==== voted === */
.board .tile.nor.voted, .board .tile.alt.voted {
    /* background-color: rgba(88,170,238,0.8); */
    background-image: linear-gradient(yellowgreen, cornflowerblue);
}

.board .tile.nor.chosen, .board .tile.alt.chosen {
    /* background-color: rgba(88,170,238,0.6);
    border-color: rgba(88,170,238,0.9); */
    background-image: linear-gradient(cornflowerblue, yellowgreen);

    /* border-style: solid; */
}

.board .tile.nor.lastVoted, .board .tile.alt.lastVoted {
    /* background-color: rgba(88,170,238,0.4); */
}

.board .tile.nor.lastChosen, .board .tile.alt.lastChosen {
    /* background-color: rgba(70, 130, 180, 0.6);
    border-style: solid;
    cursor:pointer; */
}

.board .tile.nor.lastVoted.lastChosen, .board .tile.alt.lastVoted.lastChosen {
    /* border-color: deepskyblue;
    border-style: solid; */
}




